<template>
  <!-- 发运信息详情 -->
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <div>基础信息</div>
    </el-row>
    <el-form label-width="102px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="差异单号:">{{ DetailsData.handleCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="盘盈入库单号:">{{ DetailsData.surplusCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="差异时间:">{{ DetailsData.createTime }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库:">{{ DetailsData.logicWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="盘亏出库单号:">{{ DetailsData.lossCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总预期数量:">{{ DetailsData.expectPairs }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总实际数量:">{{ DetailsData.actualPairs }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总差异数量:">{{ DetailsData.abnormalPairs }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号:">
              <div style="width:255px">{{ DetailsData.taskId ? DetailsData.taskId.join():'' }}</div>
              <!-- <el-tooltip class="item" effect="dark" :content="DetailsData.taskId ? DetailsData.taskId.join():''" placement="top-start">
                {{ DetailsData.taskId ? DetailsData.taskId.join():'' }}
              </el-tooltip> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="差异类型:">{{ abnormalTypeLabel(DetailsData.abnormalType) }} </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处理时间:">{{ DetailsData.modifyTime }}</el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="处理人:">{{ DetailsData.modifyByName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="差异原因:">{{ processResultLabel(DetailsData.reason) }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处理状态:">{{ abnormalStatusLabel(DetailsData.abnormalStatus) }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <el-row class="grid-content bg-purple-dark">
      <div>差异明细</div>
    </el-row>
    <el-table ref="multipleTable" class="mb-3" :data="DetailsDataList" :header-cell-style="{background:'#fafafa'}" max-height="500px">
      <el-table-column
        type="index"
        :label="$t('page.No')"
        align="center"
      />
      <el-table-column
        prop="sku"
        label="SKU"
        align="center"
      />
      <el-table-column
        prop="platSku"
        label="PlatSKU"
        align="center"
      />
      <el-table-column
        prop="expectPairs"
        label="预期数量"
        align="center"
      />
      <el-table-column
        prop="actualPairs"
        label="实际数量"
        align="center"
      />
      <el-table-column
        prop="abnormalPairs"
        label="差异数量"
        align="center"
      />
    </el-table>
    <el-row class="grid-content bg-blue mt-5" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>

</template>

<script>
import { findAbnormalTaskDetail } from '@/api/inventory-result'
import { Mixin } from '@/mixin/mixin.js'
export default {
  mixins: [Mixin],
  data() {
    return {
      DetailsData: {},
      DetailsDataList: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      params: {}
    }
  },

  mounted() {
    this.DetailsData = JSON.parse(this.$route.query.row)
    this._findAbnormalTaskDetail()
  },
  methods: {
    cancle() {
      this.$router.go(-1)
    },
    // 获取详情数据
    async _findAbnormalTaskDetail() {
      const { datas } = await findAbnormalTaskDetail(this.$route.query.handleCode)
      this.DetailsDataList = datas
    }

  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
</style>
